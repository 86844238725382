<template>
  <div style="text-align: justify" class="pa-sm-8 pa-md-14">
    <v-container>
      <legal-header></legal-header>

      <p>
        <strong
          >Stoferno &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          Cookies</strong
        >
      </p>
      <p><br /></p>
      <p>
        <strong
          >&Tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&alpha;
          &Epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;: 01/10/2020</strong
        >
      </p>
      <p><br /></p>
      <p>
        <strong
          >&Pi;&Lambda;&Eta;&Rho;&Omicron;&Phi;&Omicron;&Rho;&Iota;&Epsilon;&Sigma;</strong
        >
      </p>
      <p>
        &Sigma;&tau;&omicron; Stoferno
        &theta;&epsilon;&omega;&rho;&omicron;ύ&mu;&epsilon;
        &sigma;&eta;&mu;&alpha;&nu;&tau;&iota;&kappa;ό &nu;&alpha;
        &xi;έ&rho;&epsilon;&iota;&sigmaf; &pi;&omicron;&iota;&alpha;
        &epsilon;ί&nu;&alpha;&iota; &tau;&alpha; cookies &pi;&omicron;&upsilon;
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
        &sigma;&tau;&eta;&nu;
        &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
        &mu;&alpha;&sigmaf; &alpha;&lambda;&lambda;ά &kappa;&alpha;&iota;
        &gamma;&iota;&alpha; &pi;&omicron;&iota;&omicron;&sigmaf;
        &lambda;ό&gamma;&omicron;&upsilon;&sigmaf; &tau;&alpha;
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;.
        &Sigma;&tau;ό&chi;&omicron;&sigmaf; &mu;&alpha;&sigmaf;
        &epsilon;ί&nu;&alpha;&iota; &eta; &sigma;&omega;&sigma;&tau;ή
        &epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;ή &sigma;&omicron;&upsilon;
        &alpha;&lambda;&lambda;ά &kappa;&alpha;&iota; &eta;
        &kappa;&alpha;&lambda;ύ&tau;&epsilon;&rho;&eta;
        &epsilon;&mu;&pi;&epsilon;&iota;&rho;ί&alpha;
        &pi;&lambda;&omicron;ή&gamma;&eta;&sigma;&eta;&sigmaf;
        &sigma;&omicron;&upsilon; &sigma;&tau;&omicron;&nu;
        &iota;&sigma;&tau;ό&tau;&omicron;&pi;ό &mu;&alpha;&sigmaf;.
      </p>
      <p>
        <strong
          >&Gamma;&Iota;&Alpha;&Tau;&Iota;
          &Chi;&Rho;&Eta;&Sigma;&Iota;&Mu;&Omicron;&Pi;&Omicron;&Iota;&Omicron;&Upsilon;&Mu;&Epsilon;
          COOKIES;</strong
        >
      </p>
      <p>
        &Chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
        cookies &gamma;&iota;&alpha; &tau;&eta;&nu;
        &beta;&epsilon;&lambda;&tau;ί&omega;&sigma;&eta; &tau;&eta;&sigmaf;
        &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
        &tau;&omicron;&upsilon; stoferno.gr, &tau;&eta;
        &sigma;&omega;&sigma;&tau;ή
        &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;&eta;
        &sigma;&omicron;&upsilon;, &tau;&eta;&nu;
        &epsilon;ύ&kappa;&omicron;&lambda;&eta;
        &sigma;ύ&nu;&delta;&epsilon;&sigma;ή &sigma;&omicron;&upsilon;
        &kappa;&alpha;&iota; &tau;&eta;&nu; &omicron;&mu;&alpha;&lambda;ή
        &mu;&epsilon;&tau;&alpha;&kappa;ί&nu;&eta;&sigma;ή
        &sigma;&omicron;&upsilon; &sigma;&tau;&iota;&sigmaf;
        &epsilon;&pi;&iota;&mu;έ&rho;&omicron;&upsilon;&sigmaf;
        &sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf;
        &tau;&omicron;&upsilon;.
        &Pi;&alpha;&rho;ά&lambda;&lambda;&eta;&lambda;&alpha; &tau;&alpha;
        cookies &mu;&alpha;&sigmaf;
        &epsilon;&pi;&iota;&tau;&rho;έ&pi;&omicron;&upsilon;&nu; &nu;&alpha;
        &sigma;&omicron;&upsilon;
        &pi;&alpha;&rho;&omicron;&upsilon;&sigma;&iota;ά&sigma;&omicron;&upsilon;&mu;&epsilon;
        &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;&iota;&kappa;ό &amp;
        &epsilon;&pi;&iota;&mu;&omicron;&rho;&phi;&omega;&tau;&iota;&kappa;ό
        &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
        &sigma;&chi;&epsilon;&tau;&iota;&kappa;ό &mu;&epsilon; &tau;&alpha;
        &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&alpha;
        &kappa;&alpha;&iota; &tau;&iota;&sigmaf;
        &alpha;&nu;ά&gamma;&kappa;&epsilon;&sigmaf; &sigma;&omicron;&upsilon;.
      </p>
      <p>
        &Epsilon;&pi;ί&sigma;&eta;&sigmaf;, &tau;&alpha; cookies
        &mu;&alpha;&sigmaf; &beta;&omicron;&eta;&theta;&omicron;ύ&nu;
        &nu;&alpha;
        &alpha;&nu;&alpha;&lambda;ύ&sigma;&omicron;&upsilon;&mu;&epsilon;
        &pi;ώ&sigmaf; &omicron;&iota;
        &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigmaf;
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;
        &tau;&omicron;&nu; &iota;&sigma;&tau;ό&tau;&omicron;&pi;ό
        &mu;&alpha;&sigmaf;, &pi;ώ&sigmaf;
        &pi;&epsilon;&rho;&iota;&eta;&gamma;&omicron;ύ&nu;&tau;&alpha;&iota;
        &kappa;&alpha;&iota; &pi;&omicron;&upsilon;
        &delta;&upsilon;&sigma;&kappa;&omicron;&lambda;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;.
        &Mu;&epsilon; &alpha;&upsilon;&tau;ό &tau;&omicron;&nu;
        &tau;&rho;ό&pi;&omicron; &mu;&pi;&omicron;&rho;&omicron;ύ&mu;&epsilon;
        &sigma;&upsilon;&nu;&epsilon;&chi;ώ&sigmaf; &nu;&alpha;
        &beta;&epsilon;&lambda;&tau;&iota;&sigma;&tau;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
        &tau;&eta;
        &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
        &tau;&omicron;&upsilon; e-shop &kappa;&alpha;&iota; &nu;&alpha;
        &alpha;&nu;&tau;&iota;&mu;&epsilon;&tau;&omega;&pi;ί&zeta;&omicron;&upsilon;&mu;&epsilon;
        &tau;&upsilon;&chi;ό&nu;
        &pi;&rho;&omicron;&beta;&lambda;ή&mu;&alpha;&tau;&alpha;
        &pi;&omicron;&upsilon; &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
        &pi;&rho;&omicron;&kappa;ύ&pi;&tau;&omicron;&upsilon;&nu;.
      </p>
      <p>
        Ό&lambda;&epsilon;&sigmaf; &omicron;&iota;
        &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
        &pi;&omicron;&upsilon;
        &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&nu;&tau;&alpha;&iota;
        &alpha;&pi;ό &alpha;&upsilon;&tau;ά &tau;&alpha; cookies
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
        &mu;ό&nu;&omicron; &gamma;&iota;&alpha; &nu;&alpha;
        &beta;&epsilon;&lambda;&tau;&iota;ώ&sigma;&omicron;&upsilon;&mu;&epsilon;
        &tau;&eta; &delta;&iota;&kappa;ή &sigma;&omicron;&upsilon;
        &alpha;&gamma;&omicron;&rho;&alpha;&sigma;&tau;&iota;&kappa;ή
        &epsilon;&mu;&pi;&epsilon;&iota;&rho;ί&alpha;, &tau;&eta;
        &delta;&omicron;&mu;ή &kappa;&alpha;&iota; &tau;&omicron;
        &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
        &tau;&omicron;&upsilon; stoferno.gr.
      </p>
      <p>
        <strong
          >&Tau;&Iota; &Epsilon;&Iota;&Nu;&Alpha;&Iota; &Tau;&Alpha;
          COOKIES;</strong
        >
      </p>
      <p>
        &Tau;&alpha; cookies &epsilon;ί&nu;&alpha;&iota;
        &kappa;&omicron;&mu;&mu;ά&tau;&iota;&alpha;
        &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&alpha;&sigmaf;
        &mu;&epsilon; &tau;&eta;&nu; &mu;&omicron;&rho;&phi;ή
        &pi;&omicron;&lambda;ύ &mu;&iota;&kappa;&rho;&omicron;ύ
        &kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;,
        &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
        &sigma;&tau;&omicron;&nu; browser &pi;&omicron;&upsilon;
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί&sigmaf;
        &sigma;&tau;&omicron; PC &sigma;&tau;&omicron; Smartphone &amp;
        &tau;&omicron; tablet &sigma;&omicron;&upsilon; (Chrome, Mozilla Firefox
        &kappa;&lambda;&pi;),
        &beta;&omicron;&eta;&theta;ώ&nu;&tau;&alpha;&sigmaf; &tau;&eta;&nu;
        &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&epsilon;&sigma;&mu;&alpha;&tau;&iota;&kappa;ό&tau;&epsilon;&rho;&eta;
        &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
        &tau;&omicron;&upsilon; site &mu;&alpha;&sigmaf;. &Tau;&alpha; Cookies
        &sigma;&epsilon; &kappa;&alpha;&mu;ί&alpha;
        &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &delta;&epsilon;&nu;
        &pi;&rho;&omicron;&kappa;&alpha;&lambda;&omicron;ύ&nu;
        &beta;&lambda;ά&beta;&epsilon;&sigmaf;
        &sigma;&tau;&omicron;&upsilon;&sigmaf;
        &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;&omicron;ύ&sigmaf;
        &upsilon;&pi;&omicron;&lambda;&omicron;&gamma;&iota;&sigma;&tau;έ&sigmaf;
        &tau;&omega;&nu; &chi;&rho;&eta;&sigma;&tau;ώ&nu;
        &omicron;ύ&tau;&epsilon; &kappa;&alpha;&iota; &sigma;&tau;&alpha;
        &alpha;&rho;&chi;&epsilon;ί&alpha; &pi;&omicron;&upsilon;
        &phi;&upsilon;&lambda;ά&sigma;&sigma;&omicron;&nu;&tau;&alpha;&iota;
        &sigma;&epsilon; &alpha;&upsilon;&tau;&omicron;ύ&sigmaf;.
        &Omicron;&iota;
        &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
        &pi;&omicron;&upsilon;
        &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
        &sigma;&tau;&alpha; cookies
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
        &gamma;&iota;&alpha; &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf;
        &alpha;&nu;&alpha;&gamma;&nu;ώ&rho;&iota;&sigma;&eta;&sigmaf; &amp;
        &beta;&epsilon;&lambda;&tau;&iota;&sigma;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;&sigmaf;.
      </p>
      <p>
        <strong
          >&Kappa;&Alpha;&Tau;&Eta;&Gamma;&Omicron;&Rho;&Iota;&Epsilon;&Sigma;</strong
        >
      </p>
      <p>
        <strong
          >1) &Alpha;&nu;&alpha;&gamma;&kappa;&alpha;ί&alpha; Cookies</strong
        >
      </p>
      <p>
        &Epsilon;&pi;&iota;&tau;&rho;έ&pi;&omicron;&upsilon;&nu; &tau;&eta;&nu;
        &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta;
        &beta;&alpha;&sigma;&iota;&kappa;ώ&nu;
        &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&iota;ώ&nu;
        &tau;&omicron;&upsilon; site, ό&pi;&omega;&sigmaf; &tau;&eta;&nu;
        &pi;&rho;&omicron;&sigma;&theta;ή&kappa;&eta;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &sigma;&tau;&omicron;
        &kappa;&alpha;&lambda;ά&theta;&iota;, &tau;&eta;&nu;
        &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή
        &pi;&lambda;&eta;&rho;&omega;&mu;ή &kappa;&alpha;&iota; &tau;&eta;&nu;
        &alpha;&pi;&omicron;&theta;ή&kappa;&epsilon;&upsilon;&sigma;&eta;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &sigma;&tau;&eta; wish-list.
        &Chi;&omega;&rho;ί&sigmaf; &alpha;&upsilon;&tau;ά &tau;&alpha;
        &alpha;&pi;&omicron;&lambda;ύ&tau;&omega;&sigmaf;
        &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&alpha; Cookies,
        &pi;&lambda;ή&tau;&tau;&epsilon;&tau;&alpha;&iota;
        ά&mu;&epsilon;&sigma;&alpha; &eta; &omicron;&mu;&alpha;&lambda;ή
        &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
        &tau;&omicron;&upsilon; e-shop &alpha;&lambda;&lambda;ά
        &upsilon;&pi;&omicron;&beta;&alpha;&theta;&mu;ί&zeta;&epsilon;&tau;&alpha;&iota;
        &kappa;&alpha;&iota; &eta;
        &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ή
        &sigma;&omicron;&upsilon; &epsilon;&mu;&pi;&epsilon;&iota;&rho;ί&alpha;
        &pi;&lambda;&omicron;ή&gamma;&eta;&sigma;&eta;&sigmaf;
        &mu;&iota;&alpha;&sigmaf; &kappa;&alpha;&iota;
        &beta;&alpha;&sigma;&iota;&kappa;έ&sigmaf; e-commerce
        &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&epsilon;&sigmaf;
        &upsilon;&pi;&omicron;&lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&omicron;ύ&nu;.
      </p>
      <p>
        <strong
          >2) Cookies
          &epsilon;&pi;&iota;&delta;ό&sigma;&epsilon;&omega;&nu;</strong
        >
      </p>
      <p>
        &Tau;&alpha; cookies
        &epsilon;&pi;&iota;&delta;ό&sigma;&epsilon;&omega;&nu;
        &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&upsilon;&nu;
        &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
        &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon; &tau;&omicron;
        &pi;ώ&sigmaf; &omicron;&iota;
        &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigmaf;
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;
        &tau;&eta;&nu;
        &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;.
        &Mu;&alpha;&sigmaf;
        &epsilon;&pi;&iota;&tau;&rho;έ&pi;&omicron;&upsilon;&nu; &nu;&alpha;
        &delta;&omicron;ύ&mu;&epsilon; &pi;&omicron;&iota;&epsilon;&sigmaf;
        &sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf;
        &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&omicron;&nu;&tau;&alpha;&iota;
        &pi;&iota;&omicron; &sigma;&upsilon;&chi;&nu;ά ή &mu;&alpha;&sigmaf;
        &delta;&epsilon;ί&chi;&nu;&omicron;&upsilon;&nu; &epsilon;ά&nu;
        &alpha;&nu;&tau;&iota;&mu;&epsilon;&tau;&omega;&pi;ί&zeta;&omicron;&upsilon;&nu;
        &kappa;ά&pi;&omicron;&iota;&omicron;
        &pi;&rho;ό&beta;&lambda;&eta;&mu;&alpha; &kappa;&alpha;&tau;ά
        &tau;&eta;&nu; &pi;&lambda;&omicron;ή&gamma;&eta;&sigma;&eta;.
        &Alpha;&upsilon;&tau;ά &tau;&alpha; cookies &delta;&epsilon;&nu;
        &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&upsilon;&nu;
        &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
        &pi;&omicron;&upsilon;
        &pi;&rho;&omicron;&sigma;&delta;&iota;&omicron;&rho;ί&zeta;&omicron;&upsilon;&nu;
        &tau;&omicron;&nu; &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&eta;.
        Ό&lambda;&epsilon;&sigmaf; &omicron;&iota;
        &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
        &pi;&omicron;&upsilon;
        &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&upsilon;&nu;
        &alpha;&upsilon;&tau;ά &tau;&alpha; cookies &epsilon;ί&nu;&alpha;&iota;
        &sigma;&upsilon;&gamma;&kappa;&epsilon;&nu;&tau;&rho;&omega;&tau;&iota;&kappa;έ&sigmaf;
        &kappa;&alpha;&iota;
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
        &mu;ό&nu;&omicron; &gamma;&iota;&alpha; &nu;&alpha; &tau;&eta;
        &beta;&epsilon;&lambda;&tau;ί&omega;&sigma;&eta; &tau;&omicron;&upsilon;
        &tau;&rho;ό&pi;&omicron;&upsilon;
        &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
        &tau;&omicron;&upsilon; stoferno.gr.
      </p>
      <p>
        <strong
          >3) Cookies
          &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&iota;&kappa;ό&tau;&eta;&tau;&alpha;&sigmaf;</strong
        >
      </p>
      <p>
        &Alpha;&upsilon;&tau;ά &tau;&alpha; cookies
        &laquo;&alpha;&pi;&omicron;&mu;&nu;&eta;&mu;&omicron;&nu;&epsilon;ύ&omicron;&upsilon;&nu;&raquo;
        &tau;&iota;&sigmaf;
        &pi;&rho;&omicron;&tau;&iota;&mu;ή&sigma;&epsilon;&iota;&sigmaf;
        &sigma;&omicron;&upsilon; &kappa;&alpha;&tau;ά &tau;&eta;&nu;
        &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;&eta;
        &sigma;&omicron;&upsilon; &sigma;&tau;&omicron; site
        &mu;&alpha;&sigmaf;, ώ&sigma;&tau;&epsilon; &nu;&alpha;
        &mu;&pi;&omicron;&rho;&omicron;ύ&mu;&epsilon; &nu;&alpha;
        &sigma;&omicron;&upsilon;
        &pi;&rho;&omicron;&tau;&epsilon;ί&nu;&omicron;&upsilon;&mu;&epsilon;
        &tau;&alpha; &kappa;&alpha;&tau;ά&lambda;&lambda;&eta;&lambda;&alpha;
        &pi;&rho;&omicron;ϊό&nu;&tau;&alpha; &mu;&epsilon; &beta;ά&sigma;&eta;
        &tau;&iota;&sigmaf; &alpha;&nu;ά&gamma;&kappa;&epsilon;&sigmaf;
        &sigma;&omicron;&upsilon;. &Mu;&epsilon; &alpha;&upsilon;&tau;ά
        &tau;&alpha; cookies
        &alpha;&pi;&omicron;&lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&iota;&sigmaf;
        &mu;&iota;&alpha;
        &epsilon;&xi;&alpha;&tau;&omicron;&mu;&iota;&kappa;&epsilon;&upsilon;&mu;έ&nu;&eta;
        &epsilon;&kappa;&delta;&omicron;&chi;ή &tau;&omicron;&upsilon;
        stoferno.gr &mu;&epsilon;
        &alpha;&pi;&omicron;&tau;έ&lambda;&epsilon;&sigma;&mu;&alpha;
        &nu;&alpha; &beta;&rho;ί&sigma;&kappa;&epsilon;&iota;&sigmaf;
        &pi;&omicron;&lambda;ύ &pi;&iota;&omicron;
        &epsilon;ύ&kappa;&omicron;&lambda;&alpha; &alpha;&upsilon;&tau;ό
        &pi;&omicron;&upsilon; &alpha;&nu;&alpha;&zeta;&eta;&tau;ά&sigmaf;.
      </p>
      <p>
        <strong
          >4) Cookies
          &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta;&sigmaf;</strong
        >
      </p>
      <p>
        &Alpha;&upsilon;&tau;ά &tau;&alpha; cookies
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
        &gamma;&iota;&alpha; &nu;&alpha;
        &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&nu;
        &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
        &pi;&iota;&omicron; &sigma;&chi;&epsilon;&tau;&iota;&kappa;έ&sigmaf;
        &mu;&epsilon; &epsilon;&sigma;έ&nu;&alpha; &kappa;&alpha;&iota;
        &tau;&alpha;
        &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&nu;&tau;ά
        &sigma;&omicron;&upsilon;.
        &Chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
        &epsilon;&pi;ί&sigma;&eta;&sigmaf; &gamma;&iota;&alpha; &tau;&eta;&nu;
        &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή
        &sigma;&tau;&omicron;&chi;&epsilon;&upsilon;&mu;έ&nu;&eta;&sigmaf;
        &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta;&sigmaf; ή
        &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;ώ&nu; &mu;&epsilon;
        &sigma;&tau;ό&chi;&omicron; &tau;&omicron;&nu;
        &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&mu;ό
        &tau;&omega;&nu; &mu;&alpha;&zeta;&iota;&kappa;ώ&nu; ,
        &alpha;&nu;&epsilon;&pi;&iota;&theta;ύ&mu;&eta;&tau;&omega;&nu;
        &kappa;&alpha;&iota; &alpha;&nu;&omicron;ύ&sigma;&iota;&omega;&nu;
        &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;&iota;&kappa;ώ&nu;
        &mu;&eta;&nu;&upsilon;&mu;ά&tau;&omega;&nu;.
        &Pi;&alpha;&rho;ά&lambda;&lambda;&eta;&lambda;&alpha;
        &mu;&alpha;&sigmaf; &beta;&omicron;&eta;&theta;&omicron;ύ&nu;
        &sigma;&tau;&eta; &mu;έ&tau;&rho;&eta;&sigma;&eta; &tau;&eta;&sigmaf;
        &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&epsilon;&sigma;&mu;&alpha;&tau;&iota;&kappa;ό&tau;&eta;&tau;&alpha;&sigmaf;
        &tau;&omega;&nu;
        &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;&iota;&kappa;ώ&nu;
        &kappa;&alpha;&mu;&pi;&alpha;&nu;&iota;ώ&nu;.
      </p>
      <p><strong>5) Cookies Analytics</strong></p>
      <p>
        &Epsilon;ί&nu;&alpha;&iota;
        &upsilon;&pi;&omicron;&sigma;ύ&nu;&omicron;&lambda;&omicron;
        &tau;&omega;&nu; Cookies
        &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&iota;&kappa;ό&tau;&eta;&tau;&alpha;&sigmaf;
        &kappa;&alpha;&iota; &mu;&alpha;&sigmaf;
        &delta;ί&nu;&omicron;&upsilon;&nu; &tau;&eta;
        &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha; &nu;&alpha;
        &alpha;&xi;&iota;&omicron;&lambda;&omicron;&gamma;&omicron;ύ&mu;&epsilon;
        &tau;&eta;&nu;
        &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&epsilon;&sigma;&mu;&alpha;&tau;&iota;&kappa;ό&tau;&eta;&tau;&alpha;
        &tau;&omega;&nu; &delta;&iota;ά&phi;&omicron;&rho;&omega;&nu;
        &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&iota;ώ&nu;
        &tau;&omicron;&upsilon; site &mu;&alpha;&sigmaf;, έ&tau;&sigma;&iota;
        ώ&sigma;&tau;&epsilon; &nu;&alpha;
        &beta;&epsilon;&lambda;&tau;&iota;ώ&nu;&omicron;&upsilon;&mu;&epsilon;
        &sigma;&upsilon;&nu;&epsilon;&chi;ώ&sigmaf; &tau;&eta;&nu;
        &epsilon;&mu;&pi;&epsilon;&iota;&rho;ί&alpha; &pi;&omicron;&upsilon;
        &sigma;&omicron;&upsilon;
        &pi;&rho;&omicron;&sigma;&phi;έ&rho;&omicron;&upsilon;&mu;&epsilon;.
      </p>
      <p>
        <strong
          >&Sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&alpha;:</strong
        >
      </p>
      <p>
        &Tau;&rho;ί&tau;&omicron;&iota;
        &pi;&rho;&omicron;&mu;&eta;&theta;&epsilon;&upsilon;&tau;έ&sigmaf;,
        &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&eta;&sigmaf;
        &tau;&eta;&sigmaf; Google, &delta;ύ&nu;&alpha;&tau;&alpha;&iota;
        &nu;&alpha; &epsilon;&mu;&phi;&alpha;&nu;ί&sigma;&omicron;&upsilon;&nu;
        &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
        &tau;&eta;&sigmaf; &Epsilon;&tau;&alpha;&iota;&rho;ί&alpha;&sigmaf;
        &sigma;&epsilon;
        &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;&sigmaf;
        &sigma;&tau;&omicron;
        &delta;&iota;&alpha;&delta;ί&kappa;&tau;&upsilon;&omicron;.
      </p>
      <p>
        &Tau;&rho;ί&tau;&omicron;&iota;
        &pi;&rho;&omicron;&mu;&eta;&theta;&epsilon;&upsilon;&tau;έ&sigmaf;,
        &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&eta;&sigmaf;
        &tau;&eta;&sigmaf; Google, &delta;ύ&nu;&alpha;&tau;&alpha;&iota;
        &nu;&alpha; &kappa;ά&nu;&omicron;&upsilon;&nu; &chi;&rho;ή&sigma;&eta;
        cookies &gamma;&iota;&alpha; &tau;&eta;&nu;
        &epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;ή,
        &beta;&epsilon;&lambda;&tau;&iota;&sigma;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
        &kappa;&alpha;&iota; &pi;&rho;&omicron;&beta;&omicron;&lambda;ή
        &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&omega;&nu;
        &pi;&omicron;&upsilon;
        &beta;&alpha;&sigma;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
        &sigma;&epsilon;
        &pi;&rho;&omicron;&eta;&gamma;&omicron;ύ&mu;&epsilon;&nu;&eta;
        &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;&eta; &tau;&omicron;&upsilon;
        &chi;&rho;ή&sigma;&tau;&eta; &sigma;&tau;&omicron;
        &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό &tau;ό&pi;&omicron;
        stoferno.gr.
      </p>
      <p>
        To stoferno.gr &delta;ύ&nu;&alpha;&tau;&alpha;&iota;
        &epsilon;&pi;ί&sigma;&eta;&sigmaf; &nu;&alpha;
        &alpha;&xi;&iota;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;
        &tau;&alpha; cookies &alpha;&pi;ό
        &pi;&rho;&omicron;&eta;&gamma;&omicron;ύ&mu;&epsilon;&nu;&eta;
        &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;&eta; &sigma;&tau;&eta;&nu;
        &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
        &tau;&eta;&sigmaf; &gamma;&iota;&alpha;
        &epsilon;&pi;&alpha;&nu;&alpha;&lambda;&eta;&pi;&tau;&iota;&kappa;ό
        &mu;ά&rho;&kappa;&epsilon;&tau;&iota;&nu;&gamma;&kappa;.
      </p>
      <p>
        &Omicron;&iota; &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
        &mu;&pi;&omicron;&rho;&omicron;ύ&nu; &nu;&alpha;
        &epsilon;&pi;&iota;&lambda;έ&xi;&omicron;&upsilon;&nu; &tau;&eta;&nu;
        &epsilon;&xi;&alpha;ί&rho;&epsilon;&sigma;ή
        &tau;&omicron;&upsilon;&sigmaf; &alpha;&pi;ό
        &tau;έ&tau;&omicron;&iota;&alpha; &chi;&rho;ή&sigma;&eta;
        &tau;&omega;&nu; cookies &alpha;&pi;ό &tau;&eta;&nu; Google,
        &epsilon;&delta;ώ.
      </p>
      <p>
        &Mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
        &rho;&upsilon;&theta;&mu;ί&sigma;&epsilon;&tau;&epsilon;
        &tau;&omicron;&nu; Browser &sigma;&alpha;&sigmaf;, &nu;&alpha;
        &sigma;&alpha;&sigmaf;
        &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&nu;&epsilon;&iota;
        &kappa;ά&theta;&epsilon; &phi;&omicron;&rho;ά &pi;&rho;&iota;&nu;
        &gamma;ί&nu;&epsilon;&iota; &eta; &lambda;ή&psi;&eta;
        &epsilon;&nu;ό&sigmaf; cookie &kappa;&alpha;&iota; &nu;&alpha;
        &alpha;&pi;&omicron;&phi;&alpha;&sigma;ί&zeta;&epsilon;&tau;&epsilon;
        &epsilon;&sigma;&epsilon;ί&sigmaf; &tau;&eta; &lambda;ή&psi;&eta;
        &tau;&omicron;&upsilon; ή &tau;&eta;&nu;
        &alpha;&pi;ό&rho;&rho;&iota;&psi;ή &tau;&omicron;&upsilon;. &Sigma;&#39;
        &alpha;&upsilon;&tau;ή &tau;&eta;&nu;
        &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
        έ&chi;&epsilon;&tau;&epsilon; &upsilon;&pi;ό&psi;&eta; ό&tau;&iota;
        &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota; &nu;&alpha;
        &mu;&eta;&nu; &epsilon;ί&sigma;&tau;&epsilon; &sigma;&epsilon;
        &theta;έ&sigma;&eta; &nu;&alpha;
        &alpha;&xi;&iota;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&tau;&epsilon;
        ό&lambda;&epsilon;&sigmaf; &tau;&iota;&sigmaf;
        &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&epsilon;&sigmaf;
        &tau;&omicron;&upsilon;.
      </p>
      <p>
        To stoferno.gr &delta;ύ&nu;&alpha;&tau;&alpha;&iota; &nu;&alpha;
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
        &tau;&iota;&sigmaf;
        &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&epsilon;&sigmaf;
        &tau;&omicron;&upsilon; Google Analytics &gamma;&iota;&alpha;
        &tau;&iota;&sigmaf;
        &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
        &pi;&rho;&omicron;&beta;&omicron;&lambda;ή&sigmaf; (&pi;.&chi;.,
        &epsilon;&pi;&alpha;&nu;&alpha;&lambda;&eta;&pi;&tau;&iota;&kappa;ό
        &mu;ά&rho;&kappa;&epsilon;&tau;&iota;&nu;&gamma;&kappa;,
        &alpha;&nu;&alpha;&phi;&omicron;&rho;έ&sigmaf;
        &epsilon;&mu;&phi;ά&nu;&iota;&sigma;&eta;&sigmaf;
        &Delta;&iota;&kappa;&tau;ύ&omicron;&upsilon;
        &epsilon;&mu;&phi;ά&nu;&iota;&sigma;&eta;&sigmaf; Google,
        &epsilon;&nu;&sigma;&omega;&mu;ά&tau;&omega;&sigma;&eta;
        &tau;&omicron;&upsilon;
        &Delta;&iota;&alpha;&chi;&epsilon;&iota;&rho;&iota;&sigma;&tau;ή
        &kappa;&alpha;&mu;&pi;ά&nu;&iota;&alpha;&sigmaf; Doubleclick
        &kappa;&alpha;&iota; &alpha;&nu;&alpha;&phi;&omicron;&rho;έ&sigmaf;
        &delta;&eta;&mu;&omicron;&gamma;&rho;&alpha;&phi;&iota;&kappa;ώ&nu;
        &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omega;&nu;
        &kappa;&alpha;&iota;
        &epsilon;&nu;&delta;&iota;&alpha;&phi;&epsilon;&rho;ό&nu;&tau;&omega;&nu;).
      </p>
      <p>
        &Chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ώ&nu;&tau;&alpha;&sigmaf;
        &tau;&iota;&sigmaf;
        &Rho;&upsilon;&theta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
        &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&omega;&nu;,
        &omicron;&iota;
        &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigmaf;
        &mu;&pi;&omicron;&rho;&omicron;ύ&nu; &nu;&alpha;
        &epsilon;&xi;&alpha;&iota;&rho;&epsilon;&theta;&omicron;ύ&nu;
        &alpha;&pi;ό &tau;&omicron; Google Analytics &gamma;&iota;&alpha;
        &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
        &pi;&rho;&omicron;&beta;&omicron;&lambda;ή&sigmaf; &kappa;&alpha;&iota;
        &nu;&alpha;
        &pi;&rho;&omicron;&sigma;&alpha;&rho;&mu;ό&sigma;&omicron;&upsilon;&nu;
        &tau;&iota;&sigmaf;
        &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
        &tau;&omicron;&upsilon; &Delta;&iota;&kappa;&tau;ύ&omicron;&upsilon;
        &epsilon;&mu;&phi;ά&nu;&iota;&sigma;&eta;&sigmaf; Google.
      </p>
      <p>
        &Epsilon;&delta;ώ
        &beta;&rho;ί&sigma;&kappa;&omicron;&nu;&tau;&alpha;&iota;
        &omicron;&iota;
        &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&epsilon;&sigmaf;
        &epsilon;&pi;&iota;&lambda;&omicron;&gamma;έ&sigmaf;
        &epsilon;&xi;&alpha;ί&rho;&epsilon;&sigma;&eta;&sigmaf;
        &tau;&omicron;&upsilon; Google Analytics &gamma;&iota;&alpha;
        &tau;&omicron;&nu; &iota;&sigma;&tau;ό.
      </p>
      <p>
        To stoferno.gr
        &sigma;&upsilon;&mu;&mu;&omicron;&rho;&phi;ώ&nu;&epsilon;&tau;&alpha;&iota;
        &mu;&epsilon; &tau;&eta;&nu;
        &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
        &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&omega;&nu;
        &beta;ά&sigma;&epsilon;&iota;
        &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&omicron;&sigmaf;
        &tau;&omicron;&upsilon; Google AdWords &kappa;&alpha;&iota;
        &tau;&omicron;&upsilon;&sigmaf;
        &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&mu;&omicron;ύ&sigmaf;
        &gamma;&iota;&alpha;
        &epsilon;&upsilon;&alpha;ί&sigma;&theta;&eta;&tau;&epsilon;&sigmaf;
        &kappa;&alpha;&tau;&eta;&gamma;&omicron;&rho;ί&epsilon;&sigmaf;
        &kappa;&alpha;&iota;:
      </p>
      <p>
        &Chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
        &epsilon;&pi;&alpha;&nu;&alpha;&lambda;&eta;&pi;&tau;&iota;&kappa;ό
        &mu;ά&rho;&kappa;&epsilon;&tau;&iota;&nu;&gamma;&kappa; &mu;&epsilon;
        &tau;&omicron; Google Analytics &gamma;&iota;&alpha;
        &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta; &sigma;&tau;&omicron;
        &delta;&iota;&alpha;&delta;ί&kappa;&tau;&upsilon;&omicron;.
      </p>
      <p>
        To stoferno.gr &kappa;&alpha;&iota; &tau;&rho;ί&tau;&omicron;&iota;
        &pi;&rho;&omicron;&mu;&eta;&theta;&epsilon;&upsilon;&tau;έ&sigmaf;,
        &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&eta;&sigmaf;
        &tau;&eta;&sigmaf; Google,
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;
        &mu;&alpha;&zeta;ί cookie &alpha;&rho;&chi;&iota;&kappa;&omicron;ύ
        &kappa;&alpha;&tau;&alpha;&sigma;&kappa;&epsilon;&upsilon;&alpha;&sigma;&tau;ή
        (ό&pi;&omega;&sigmaf; &tau;&omicron; cookie &tau;&omicron;&upsilon;
        Google Analytics) &kappa;&alpha;&iota; cookie
        &tau;&rho;ί&tau;&omicron;&upsilon; &mu;έ&rho;&omicron;&upsilon;&sigmaf;
        (ό&pi;&omega;&sigmaf; &tau;&omicron; cookie DoubleClick),
        &gamma;&iota;&alpha; &tau;&eta;&nu;
        &epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;, &tau;&eta;
        &beta;&epsilon;&lambda;&tau;&iota;&sigma;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
        &kappa;&alpha;&iota; &tau;&eta;&nu;
        &pi;&rho;&omicron;&beta;&omicron;&lambda;ή
        &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&omega;&nu;,
        &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&iota;&sigmaf;
        &pi;&rho;&omicron;&eta;&gamma;&omicron;ύ&mu;&epsilon;&nu;&epsilon;&sigmaf;
        &epsilon;&pi;&iota;&sigma;&kappa;έ&psi;&epsilon;&iota;&sigmaf;
        &kappa;ά&pi;&omicron;&iota;&omega;&nu; &chi;&rho;&eta;&sigma;&tau;ώ&nu;
        &sigma;&tau;&omicron;&nu; &iota;&sigma;&tau;ό&tau;&omicron;&pi;ό
        &tau;&eta;&sigmaf;.
      </p>
      <p>
        To stoferno.gr &kappa;&alpha;&iota; &omicron;&iota;
        &tau;&rho;ί&tau;&omicron;&iota;
        &pi;&rho;&omicron;&mu;&eta;&theta;&epsilon;&upsilon;&tau;έ&sigmaf;,
        &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&eta;&sigmaf;
        &tau;&eta;&sigmaf; Google,
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;
        &mu;&alpha;&zeta;ί cookie &alpha;&rho;&chi;&iota;&kappa;&omicron;ύ
        &kappa;&alpha;&tau;&alpha;&sigma;&kappa;&epsilon;&upsilon;&alpha;&sigma;&tau;ή
        (ό&pi;&omega;&sigmaf; &tau;&omicron; cookie &tau;&omicron;&upsilon;
        Google Analytics) &kappa;&alpha;&iota; cookie
        &tau;&rho;ί&tau;&omicron;&upsilon; &mu;έ&rho;&omicron;&upsilon;&sigmaf;
        (ό&pi;&omega;&sigmaf; &tau;&omicron; cookie DoubleClick),
        &gamma;&iota;&alpha; &tau;&eta;&nu;
        &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta;
        &alpha;&nu;&alpha;&phi;&omicron;&rho;ώ&nu;
        &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
        &tau;&omicron;&nu; &tau;&rho;ό&pi;&omicron; &mu;&epsilon;
        &tau;&omicron;&nu; &omicron;&pi;&omicron;ί&omicron; &omicron;&iota;
        &epsilon;&mu;&phi;&alpha;&nu;ί&sigma;&epsilon;&iota;&sigmaf;
        &tau;&omega;&nu;
        &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;ώ&nu;
        &tau;&eta;&sigmaf; stoferno.gr, ά&lambda;&lambda;&epsilon;&sigmaf;
        &chi;&rho;ή&sigma;&epsilon;&iota;&sigmaf;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
        &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta;&sigmaf;
        &kappa;&alpha;&iota; &omicron;&iota;
        &alpha;&lambda;&lambda;&eta;&lambda;&epsilon;&pi;&iota;&delta;&rho;ά&sigma;&epsilon;&iota;&sigmaf;
        &mu;&epsilon; &alpha;&upsilon;&tau;έ&sigmaf; &tau;&iota;&sigmaf;
        &epsilon;&mu;&phi;&alpha;&nu;ί&sigma;&epsilon;&iota;&sigmaf;
        &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&omega;&nu;
        &kappa;&alpha;&iota;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
        &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta;&sigmaf;
        &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
        &mu;&epsilon;
        &epsilon;&pi;&iota;&sigma;&kappa;έ&psi;&epsilon;&iota;&sigmaf;
        &sigma;&tau;&omicron;&nu; &iota;&sigma;&tau;ό&tau;&omicron;&pi;ό
        &tau;&eta;&sigmaf; stoferno.gr.
      </p>
      <p>
        To stoferno.gr &delta;ύ&nu;&alpha;&tau;&alpha;&iota; &nu;&alpha;
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
        &tau;&alpha; &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
        &alpha;&pi;ό &tau;&eta; &delta;&iota;&alpha;&phi;ή&mu;&iota;&sigma;&eta;
        &beta;ά&sigma;&epsilon;&iota;
        &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&omicron;&sigmaf;
        &tau;&eta;&sigmaf; Google ή &tau;&alpha;
        &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
        &kappa;&omicron;&iota;&nu;&omicron;ύ &tau;&rho;ί&tau;&omicron;&upsilon;
        &mu;έ&rho;&omicron;&upsilon;&sigmaf; (ό&pi;&omega;&sigmaf; &eta;
        &eta;&lambda;&iota;&kappa;ί&alpha;, &tau;&omicron;
        &phi;ύ&lambda;&omicron; &kappa;&alpha;&iota; &tau;&alpha;
        &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&alpha;)
        &mu;&epsilon; &tau;&omicron; Google Analytics.
      </p>
      <p>
        &Delta;&iota;&alpha;&tau;&eta;&rho;&omicron;ύ&mu;&epsilon;
        &tau;&omicron; &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
        &nu;&alpha; &alpha;&lambda;&lambda;ά&xi;&omicron;&upsilon;&mu;&epsilon;
        &alpha;&upsilon;&tau;ή &tau;&eta;&nu;
        &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή &gamma;&iota;&alpha;
        cookies
        &omicron;&pi;&omicron;&tau;&epsilon;&delta;ή&pi;&omicron;&tau;&epsilon;.
        &Pi;&alpha;&rho;&alpha;&kappa;&alpha;&lambda;ώ
        &epsilon;&nu;&tau;ό&pi;&iota;&sigma;&epsilon; &tau;&omicron;
        &sigma;&eta;&mu;&epsilon;ί&omicron;
        &laquo;&Tau;&Epsilon;&Lambda;&Epsilon;&Upsilon;&Tau;&Alpha;&Iota;&Alpha;
        &Alpha;&Nu;&Alpha;&Theta;&Epsilon;&Omega;&Rho;&Eta;&Sigma;&Eta;&raquo;
        &sigma;&tau;&eta;&nu; &kappa;&omicron;&rho;&upsilon;&phi;ή
        &tau;&eta;&sigmaf; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;&sigmaf;
        &sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;, &gamma;&iota;&alpha;
        &nu;&alpha; &delta;&epsilon;&iota;&sigmaf; &pi;ό&tau;&epsilon;
        &alpha;&nu;&alpha;&theta;&epsilon;&omega;&rho;ή&theta;&eta;&kappa;&epsilon;
        &gamma;&iota;&alpha;
        &tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&alpha;
        &phi;&omicron;&rho;ά &eta; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
        &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή Cookies.
        &Omicron;&pi;&omicron;&iota;&epsilon;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
        &alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf; &sigma;&tau;&eta;&nu;
        &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
        &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή Cookies &theta;&alpha;
        &iota;&sigma;&chi;ύ&sigma;&omicron;&upsilon;&nu; &alpha;&pi;ό &tau;&eta;
        &sigma;&tau;&iota;&gamma;&mu;ή &pi;&omicron;&upsilon; &eta;
        &alpha;&nu;&alpha;&theta;&epsilon;&omega;&rho;&eta;&mu;έ&nu;&eta;
        &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή Cookies
        &kappa;&alpha;&tau;&alpha;&sigma;&tau;&epsilon;ί
        &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&eta;
        &sigma;&tau;&omicron;&nu;
        &Iota;&sigma;&tau;&omicron;&chi;ώ&rho;&omicron;.
      </p>
      <p>
        &Delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;έ&sigmaf;
        &tau;&rho;ί&tau;&omega;&nu; &mu;&epsilon;&rho;ώ&nu; &kappa;&alpha;&iota;
        ά&lambda;&lambda;&epsilon;&sigmaf;
        &epsilon;&pi;&iota;&chi;&epsilon;&iota;&rho;ή&sigma;&epsilon;&iota;&sigmaf;
        &pi;&omicron;&upsilon;
        &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&alpha;&sigma;&tau;&epsilon;
        &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;
        &tau;&alpha; &delta;&iota;&kappa;ά &tau;&omicron;&upsilon;&sigmaf;
        Cookies &gamma;&iota;&alpha; &nu;&alpha;
        &sigma;&upsilon;&lambda;&lambda;έ&xi;&omicron;&upsilon;&nu;
        &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
        &pi;&omicron;&upsilon; &alpha;&phi;&omicron;&rho;&omicron;ύ&nu;
        &sigma;&tau;&iota;&sigmaf;
        &delta;&rho;&alpha;&sigma;&tau;&eta;&rho;&iota;ό&tau;&eta;&tau;έ&sigmaf;
        &sigma;&omicron;&upsilon; &sigma;&tau;&omicron;&nu;
        &Iota;&sigma;&tau;&omicron;&chi;ώ&rho;&omicron; &mu;&alpha;&sigmaf;.
        &Delta;&epsilon;&nu;
        &epsilon;&lambda;έ&gamma;&chi;&omicron;&upsilon;&mu;&epsilon;
        &alpha;&upsilon;&tau;ά &tau;&alpha; Cookies.
      </p>
      <p><br /></p>
    </v-container>
  </div>
</template>

<script>
import LegalHeader from "./LegalHeader.vue";
export default {
  components: { LegalHeader },
  name: "Cookies",
  data() {
    return {};
  },
};
</script>

<style></style>
